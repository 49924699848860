<template>
  <div>
    <PageBar Image="img/job_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="jobsContainer">
        <h2 v-if="!Menu">實習與工作供需媒合</h2>
        <h2 v-else>{{ Menu.Title }}</h2>
        <div class="sortBy">
          <span>排序依</span>
          <select v-model="Sort">
            <option :value="0">發布日期</option>
            <option :value="1">名稱筆劃</option>
          </select>
        </div>
        <div class="courseList">
          <div id="waterfallArea">
            <div class="content_box" v-for="d in Documents.Data" :key="d.NewsID">
              <a href="javascript:void(0);" @click="go(d)"><img :src="d.ImageUrl | contentCDN" />
                <h3>{{ d.Title }}</h3>
                <div class="date">{{ d.CreateTime | dateFormat }}</div>
              </a>
            </div>
          </div>
          <div class="pagination">
            <ul>
              <li v-for="p in Pages" :key="p">
                <a :class="{ act: p == Page }" href="javascript:void(0);" @click="goPage(p)">{{ p }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";

import moment from "moment";
import user from "../plugins/user";
function initial() {
  $(function () {
    //Trigger layout after each image loads and initialise Mansonry
    if ($(window).width() > 900) {
      $("#waterfallArea").imagesLoaded(function () {
        $("#waterfallArea").masonry({
          itemSelector: ".content_box",
          animate: true,
          horizontalOrder: true,
          originTop: true,
        });
      });

      //Fade Out the loading screen when all images loaded
      $("#waterfallArea")
        .imagesLoaded()
        .always(function (instance) {
          $(".loadingScreen").fadeOut();
        });
    }
  });

  (function ($) {
    $(window).on("load", function () {
      $(".scrollContent").mCustomScrollbar();
    });
  })(jQuery);

  $(".logos_slide").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
}
const PageSize = 8;

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Documents: {
        Data: [],
        Count: 0,
      },
      Sort: 0,
      Page: 1,
      MaxPage: 0,
      Pages: [],
      Meun: null,
    };
  },
  mounted() {
    window.model = this;
    this.setBase("media_platform");
    this.load();
  },
  methods: {
    async load() {
      var result = await this.$api.getNews(
        this.Page,
        this.$route.params.id,
        this.Sort
      );
      this.Documents = result.Documents;
      this.Menu = result.Menu;
      this.Breadcrumbs = [
        { Title: this.Menu.Title, Url: "#" },
      ];
      this.Pages = [];
      this.MaxPage = Math.ceil(this.Documents.Count / PageSize);
      for (var p = -4; p < 4; p++) {
        var px = p + this.Page;
        if (px > 0 && px <= this.MaxPage) {
          this.Pages.push(px);
        }
      }
      setTimeout(() => {
        initial();
        setTimeout(() => {
          try {
            $("#waterfallArea").masonry("reloadItems");
          } catch (e) { }
        });
      });
    },
    go(doc) {
      this.$router.push("/news/" + doc.NewsID);
    },
    goPage(p) {
      if (this.Page != p) {
        this.$router.push("/?p=" + p);
      }
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
    Sort(newValue, oldValue) {
      this.load();
    },
  },
};
</script>